import React, { useState } from 'react'
import Menu from '../../components/Menu'
import Image from '../../components/Lendis/Image'
import Footer from '../../components/Footer'
import Layout from '../../components/layout'
import Button from '../../components/Share/Button'
import CheckBox from '../../components/Share/CheckBox'
import Header from '../../components/Header'
import GoogleTagManagerService from '../../services/GoogleTagManagerService'
import Cookies from 'js-cookie'

const FloorPlanPage = props => {
  const [email, setEmail] = useState('')
  const [firstName, setFirstName] = useState('')
  const [lastName, setLastName] = useState('')
  const [company, setCompany] = useState('')
  const [phone, setPhone] = useState('')
  const [agb, setAgb] = useState(false)
  const [openNewsletter, setOpenNewsletter] = useState(false)
  const [isSubmitted, setIsSubmitted] = useState(false)
  const [isSubmissionSuccessful, setIsSubmissionSuccessful] = useState(false)
  const gtm = new GoogleTagManagerService()

  const handleCheckBox = e => {
    setAgb(!agb)
  }

  const handleOpenNewsletterCheckBox = async e => {
    setOpenNewsletter(!openNewsletter)
  }

  const onSubmit = async e => {
    e.preventDefault()
    const formData = new FormData(e.target)
    const url = window.location.href
    const referrer = document.referrer
    const utmParams = {
      utm_source: Cookies.get('utm_source') || null,
      utm_medium: Cookies.get('utm_medium') || null,
      utm_campaign: Cookies.get('utm_campaign') || null,
      utm_content: Cookies.get('utm_content') || null,
      utm_term: Cookies.get('utm_term') || null,
      gclid: Cookies.get('gclid') || null,
      adgroupid: Cookies.get('adgroupid') || null,
    }
    const payload = {
      email,
      firstName,
      lastName,
      company,
      url,
      ...utmParams,
      referrer,
      phone,
      agb,
      openNewsletter,
    }
    for (let [key, value] of formData.entries()) {
      payload[key] = value
    }
    fetch(e.target.action, {
      method: 'POST',
      body: JSON.stringify(payload),
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
      },
    })
      .then(res => {
        setIsSubmitted(true)
        if (res.ok) {
          gtm.fireMQLEvent()
          setIsSubmissionSuccessful(true)
          setIsSubmitted(false)
        } else {
          setIsSubmissionSuccessful(false)
        }
      })
      .catch(_ => {
        setIsSubmitted(true)
        setIsSubmissionSuccessful(false)
      })
  }

  return (
    <Layout>
      <Header page="floor-plan" />
      <Menu />
      <div className="bg-lendis-gray-gradient">
        <div className=" md:mb-16 pt-32">
          <div className="container md:flex md:flex-wrap mx-auto md:items-center">
            <div className="bg-lendis-gray-gradient pl-8 pr-8 pt-16 md:w-5/12 z-10">
              <h1 className="font-semibold leading-tight text-4xl lg:mb-4">
                Grundrissplanung anfragen
              </h1>
              <p
                className={`transition-ease-2 ${
                  isSubmissionSuccessful ? 'opacity-0' : 'opacity-100'
                }`}
              >
                Fragen Sie eine individuelle Grundrissplanung an – wir melden uns umgehend bei
                Ihnen.
              </p>

              <div className="pt-8">
                <div
                  className={`transition-ease-2 ${
                    isSubmissionSuccessful ? 'opacity-100' : 'opacity-0'
                  }`}
                >
                  <div>
                    <p>Danke für Ihre Anfrage! Wir melden uns bei Ihnen in Kürze. </p>
                  </div>
                </div>
                <div
                  className={`transition-ease-2 ${
                    isSubmissionSuccessful ? 'opacity-0' : 'opacity-100'
                  }`}
                >
                  <form
                    className="flex mt-4"
                    action={process.env.FORM_WEBHOOK_URL}
                    onSubmit={onSubmit}
                    method="post"
                  >
                    <div className="flex flex-wrap">
                      <div className="w-1/2 pr-2">
                        <input
                          placeholder="Vorname"
                          value={firstName}
                          type="text"
                          required
                          onChange={e => setFirstName(e.target.value)}
                          className="h-12 mb-10 p-4 w-full py-3 "
                        />
                      </div>
                      <div className="w-1/2 pl-2">
                        <input
                          placeholder="Nachname"
                          value={lastName}
                          type="text"
                          required
                          onChange={e => setLastName(e.target.value)}
                          className="h-12 mb-10 p-4 w-full py-3 "
                        />
                      </div>
                      <div className="w-full">
                        <input
                          placeholder="Firmenname"
                          value={company}
                          type="text"
                          required
                          onChange={e => setCompany(e.target.value)}
                          className="h-12 mb-10 p-4 w-full py-3 "
                        />
                      </div>
                      <div className="w-full">
                        <input
                          placeholder="Geschäftliche E-Mail-Adresse"
                          title="Bitte geben Sie eine gültige E-Mail-Adresse an."
                          pattern="^([a-zA-Z0-9+_\-\.]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([a-zA-Z0-9\-]+\.)+))([a-zA-Z]{2,4}|[0-9]{1,3})(\]?)$"
                          value={email}
                          type="email"
                          required
                          onChange={e => setEmail(e.target.value)}
                          className="h-12 mb-10 p-4 w-full py-3 "
                        />
                      </div>
                      <div className="w-full">
                        <input
                          placeholder="Telefonnummer (optional)"
                          value={phone}
                          type="text"
                          onChange={e => setPhone(e.target.value)}
                          className="h-12 mb-10 p-4 w-full py-3 "
                        />
                      </div>
                      <div className="w-full">
                        <CheckBox
                          Label={
                            'Ich bin damit einverstanden, dass die Lendis GmbH mir Informationen zu ihrem Produktsortiment per E-Mail zuschickt.'
                          }
                          Value={agb}
                          Required={true}
                          OnChange={e => handleCheckBox(e)}
                        />
                      </div>
                      <div className="w-full">
                        <CheckBox
                          smallLabel={true}
                          Label={
                            'Ja, ich möchte regelmäßig <b>exklusive Rabatte, Tipps und Fachartikel</b> zu Büroausstattung per E-Mail erhalten.'
                          }
                          value={openNewsletter}
                          OnChange={e => handleOpenNewsletterCheckBox(e)}
                        />
                      </div>
                      <div className="w-full">
                        <div>
                          Mir ist bewusst, dass ich meine Einwilligung gegenüber der Lendis GmbH
                          jederzeit widerrufen kann.
                        </div>
                      </div>
                      <div
                        className={`text-red-500 transition-ease-2 w-full ${
                          isSubmitted && !isSubmissionSuccessful ? 'block' : 'hidden'
                        }`}
                      >
                        <p>Fehler beim Versenden. Bitte versuchen Sie es später noch einmal!</p>
                      </div>
                      <div className="w-full text-right">
                        <Button disabled={!agb} text="Jetzt anfragen" arrow />
                      </div>
                    </div>

                    <input type="hidden" name="activeCampaignListId" value="34" />
                    <input type="hidden" name="form_id" value="floor_plan_request" />
                    <input type="hidden" name="form_name" value="Grundrissplanung anfragen" />
                  </form>
                </div>
              </div>
            </div>
            <div className="flex items-center justify-center relative text-center md:w-7/12 z-10 md:pt-20 md:pb-16 md:pt-0 pt-16">
              <div className="relative">
                <Image
                  src="website/office_planning/46_person-holding-black-pen-1109541_rnpoxp.jpg"
                  size="1/2"
                  alt="person-holding"
                />
              </div>
            </div>
          </div>
          <div className=" bg-lendis-gray-gradient flex justify-end md:absolute md:float-right md:w-full z-0 md:top-0">
            <div className="bg-lendis-main h-20 w-1/2 md:h-256" />
          </div>
        </div>
      </div>
      <Footer />
    </Layout>
  )
}
export default FloorPlanPage
